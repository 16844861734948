@media (max-width: 426px){
.float-left.ml-3 {
    width: 62%;
}
}

.h1{
    padding-right: 3rem;
}

.h2{
    padding-right: 3rem;
}

.td{
    padding-right: 3rem;
}

.displayContainer1{
    display: block;
}

.displayContainer2{
    display: none;
}

@media(max-width: 576px){
    .displayContainer1{
        display: none;
    }
    
    .displayContainer2{
        display: block;
    }
}