.switch {
    --false: #E81B1B;
    --true: #009068;
   }
   
   input[type=checkbox] {
    appearance: none;
    height: 1.1rem;
    width: 2.2rem;
    background-color: #e8e8e8;
    position: relative;
    border-radius: 1em;
    cursor: pointer;
    margin-top: 15px;
    box-shadow: inset 3px 3px 5px rgba(0,0,0,0.3);
   }
   
   input[type=checkbox]::before {
    content: '';
    display: block;
    height: 1em;
    width: 1em;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 3px;
    background-color: var(--false);
    border-radius: 1em;
    transition: .3s ease;
   }
   
   input[type=checkbox]:checked::before {
    background-color: var(--true);
    left: calc(50% + 2px);
   }