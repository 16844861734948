@import './assets/plugins/bootstrap/css/bootstrap.min.css';

@import './assets/css/main.css';

@import './assets/css/theme2.css';

@import './assets/css/custom.css';

@import './assets/plugins/charts-c3/c3.min.css';

/* @import './assets/plugins/summernote/dist/summernote.css'; */



@import './assets/plugins/jvectormap/jvectormap-2.0.3.css';

@import './assets/plugins/bootstrap-multiselect/bootstrap-multiselect.css';

@import './assets/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css';

@import './assets/plugins/fullcalendar/fullcalendar.min.css';

@import './assets/plugins/jquery-steps/jquery.steps.css';

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Works for Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
input[type="date"]{
    display: inline-block;
    position: relative;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath fill='black' fill-rule='evenodd' clip-rule='evenodd' d='M4 1C4 0.447754 4.44775 0 5 0C5.55225 0 6 0.447754 6 1V2H12V1C12 0.447754 12.4478 0 13 0C13.5522 0 14 0.447754 14 1V2H15C16.6567 2 18 3.34314 18 5V7V15C18 16.6569 16.6567 18 15 18H3C1.34326 18 0 16.6569 0 15V7V5C0 3.34314 1.34326 2 3 2H4V1ZM2 8V14.5C2 15.3284 2.67163 16 3.5 16H14.5C15.3284 16 16 15.3284 16 14.5V8H2ZM16 6H2V5.5C2 4.67163 2.67163 4 3.5 4H14.5C15.3284 4 16 4.67163 16 5.5V6Z'/%3E%3C/svg%3E") no-repeat right 10px center;
    background-size: 15px;
    padding-right: 28px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}