.fixed-controls {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.priorityContainer {
  font-size: 10px;
  display: flex;
  gap: 10px;
  width: 100%;
}

.priorityCritical {
  border: 1px solid #ff5c5c;
  border-radius: 10px;
  /* width: 100%; */
  width: 25%;
  height: min-content;
  background-color: #ffabb32b;
}

.priorityHigh {
  border: 1px solid #ff952b;
  border-radius: 10px;
  /* width: 100%; */
  width: 25%;
  height: min-content;
  background-color: #ffbc8c36;
}

.priorityMedium {
  border: 1px solid #f6c32d;
  border-radius: 10px;
  /* width: 100%; */
  width: 25%;
  height: min-content;
  background-color: #ffe8ae42;
}

.priorityLow {
  border: 1px solid #308cd0;
  border-radius: 10px;
  /* width: 100%; */
  width: 25%;
  height: min-content;
  background-color: #d0f2fd59;
}

.flexHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.count {
  background-color: white;
  padding: 4px;
  border-radius: 50%;
  min-height: 28px;
  min-width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.critical {
  color: white;
  background: #ff5c5c;
  border: 1px solid #ff8b96;
  display: inline-block;
  width: 100%;
  font-weight: 600;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 2px 2px 6px rgb(0, 0, 0, 0.15);
}

.high {
  color: white;
  background: #ff952b;
  border-color: #ff9d56;
  display: inline-block;
  width: 100%;
  font-weight: 600;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 2px 2px 6px rgb(0, 0, 0, 0.15);
}

.medium {
  color: white;
  background: #f6c32d;
  border-color: #f5d276;
  display: inline-block;
  width: 100%;
  font-weight: 600;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 2px 2px 6px rgb(0, 0, 0, 0.15);
}
.low {
  color: white;
  background: #308cd0;
  border-color: #afe7f9;
  display: inline-block;
  width: 100%;
  font-weight: 600;
  padding: 5px 10px;
  /* color: white; */
  font-size: 15px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 2px 2px 6px rgb(0, 0, 0, 0.15);
}

.card {
  margin: 10px;
  border: 0px solid gray;
  border-radius: 5px;
  /* height:25vh; */
  background-color: #ffffff;
  box-shadow: 2px 2px 6px rgb(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: -1;
}

.parentCard {
  max-height: 60vh;
  overflow-y: auto;
  min-height: 10vh;
}

.button {
  border: 1px solid #28a745;
  padding: 0px 5px;
  color: #28a745;
  border-radius: 3px;
}

.button:hover {
  background-color: #28a745;
  color: white;
}

.reportContainer {
  gap: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.date {
  margin: 10px 20%;
  border: 0px solid gray;
  border-radius: 5px;
  /* height:25vh; */
  background-color: #ffffff;
  box-shadow: 2px 2px 6px rgb(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.toggleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.filterIcon {
  background-color: rgb(90, 82, 120);
  border: 1px solid #5a5278;
  border-radius: 4px;
  height: 30px;
    width: 30px;
}

.clearAll {
  color: #5a5278;
  cursor: pointer;
  font-weight: bold;
}

.leftFilterData {
  width: 35%;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.leftFilterData div {
  cursor: pointer;
}
.applyContainer {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  background-color: #5a5278;
  padding: 5px;
  height: 42px;
}

.applyButton {
  cursor: pointer;
  display: inline-block;
  color: #fff;
  width: 80px;
  text-align: center;
  padding: 7px;
}


.leftFilterBackground {
  background-color: #fff;
}

.rightFilterData label {
  margin-bottom: 2px;
}

.header {
  height: 35px;
  align-items: center;
  /* padding-right: 50px !important; */
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}


/* .notificationBellIcon{
  position: relative;
    z-index: 999;
    background: #5a5278;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
} */

.notificationBellIcon{
    background: #5a5278;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.notificationMsg{
  display: flex;
  gap: 10px;
  font-size: 11px;
  cursor: pointer;
  /* margin-bottom: 15px; */
  padding: 10px;
  border: 1px solid #ddd;
}

.notificationMsg:hover{
  background-color: #f6f9ff;
}

.userIcon{
  background-color: white;
    border-radius: 50%;
    display: flex;
    /* align-items: center; */
    color: #5fbfaf;
    position: relative;
    top: 5px;
    font-size: 1rem;
}

.notificationHeading{
  font-weight: bold;
}

.notificationDescription{
  color: #72889a;
}

.notificationArrowIcon{
  position: relative;
    right: 31px;
    text-align: end;
    top: -13px;
}


.lineHeight{
  line-height: 20px;
}

.scrollableCard {
  /* max-height: 300px; */
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox */
}

.scrollableCard::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollableCard::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

.scrollableCard::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar handle */
  border-radius: 10px; /* Roundness of the handle */
  border: 2px solid #f1f1f1; /* Padding around the handle */
}



@media(max-width: 576px){
  .notificationBellIcon{
    padding: 8px;
  }
}

@media (max-width: 1024px) {
  .priorityContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .priorityCritical,
  .priorityHigh,
  .priorityMedium,
  .priorityLow {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .priorityContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .reportContainer {
    flex-direction: column;
    gap: 0px;
    margin-bottom: 10px;
  }

  .priorityContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .toggleContainer {
    justify-content: start;
  }
}
