:root{--primary:#5a5278; --secondary:#f5733b; }
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

div.font-montserrat{
	font-family: "Roboto Flex", sans-serif !important;
}
body,.table td, .table th{
	font-size: 13px;
}

.nowrap{
	white-space: nowrap;
}
::selection {
	color: #fff;
	background: #5a5278;
}
a,
.btn-link {
	color: #5a5278;
}
.text-green,
.text-info,
.text-success,
.text-danger,
.text-primary,
.text-warning,
.mail-star.active,
.page-title {
	color: #5a5278 !important;
}
.badge-default {
	background: #3f3d56;
	color: #f1f1f1;
}
.loader {
	color: #5a5278;
}
.badge-primary {
	background-color: #5a5278;
}
.btn-primary, .swal2-confirm {
	background: #5a5278 !important;
	color: #fff !important;
	border-color: #5a5278 !important;
}
.btn-info {
	background-color: #e8769f;
	border-color: #e8769f;
}
.nav-tabs .nav-link.active {
	border-color: #5a5278;
	color: #5a5278;
}
.form-control:focus {
	box-shadow: 0 0 0 0.2rem rgba(90, 82, 120, 0.25);
}
.card,
.dropify-wrapper,
.btn-primary {
	border: 0;
}
.bg-primary,
.bg-info,
.bg-warning,
.btn-success {
	background-color: #5a5278 !important;
	border: transparent;
}
.bg-success,
.bg-danger,
.badge-success,
.progress-bar,
.btn-danger,
.btn-warning {
	background-color: #e8769f;
	border: transparent;
}
.btn-outline-primary {
	background: none !important;
	color: #5a5278 !important;
	border:1px solid #5a5278 !important;
}
.btn-outline-primary:hover {
	color: #fff !important;
	background-color: #5a5278 !important;
	border-color: #5a5278 !important;
}
.custom-control-input:checked ~ .custom-control-label::before,
.custom-radio
	.custom-control-input:disabled:checked
	~ .custom-control-label::before,
.custom-checkbox
	.custom-control-input:disabled:checked
	~ .custom-control-label::before {
	border-color: #5a5278;
	background-color: #5a5278;
}
.custom-switch-input:checked ~ .custom-switch-indicator {
	background: #5a5278;
}
.selectgroup-input:checked + .selectgroup-button {
	border-color: #5a5278;
	color: #5a5278;
	background: rgba(90, 82, 120, 0.2);
}
.imagecheck-figure:before {
	color: #e8769f !important;
}
.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active,
.wizard > .actions a,
.wizard > .actions a:hover {
	background: #5a5278;
}
.wizard > .steps .done a,
.wizard > .steps .done a:hover {
	background: rgba(90, 82, 120, 0.2);
	color: #5a5278;
}
.table th {
	color: #2c2945;
}
.table.table-custom td,
.table.table-custom th {
	background: #f1f1f1;
}
.right_chat li.online .status {
	background: #5a5278;
}
.bg-blue,
.bg-azure,
.bg-indigo,
.bg-purple,
.bg-red,
.bg-orange {
	background-color: #5a5278 !important;
}
.bg-pink,
.bg-yellow,
.bg-lime,
.bg-green,
.bg-teal,
.bg-cyan {
	background-color: #e8769f !important;
}
.bg-light-blue,
.bg-light-azure,
.bg-light-indigo,
.bg-light-lime,
.bg-light-green,
.bg-light-teal {
	background-color: rgba(90, 82, 120, 0.1);
	color: #5a5278;
}
.bg-light-pink,
.bg-light-red,
.bg-light-orange,
.bg-light-yellow,
.bg-light-cyan {
	background-color: rgba(232, 118, 159, 0.1);
	color: #e8769f;
}
.bg-light-purple {
	background-color: rgba(228, 189, 81, 0.1);
	color: #e4bd51;
}
.bg-light-gray {
	background-color: rgba(102, 106, 109, 0.1);
	color: #666a6d;
}
.avatar.avatar-blue {
	background-color: rgba(90, 82, 120, 0.1);
	color: #5a5278;
}
.avatar.avatar-azure {
	background-color: rgba(90, 82, 120, 0.1);
	color: #5a5278;
}
.avatar.avatar-indigo {
	background-color: rgba(90, 82, 120, 0.1);
	color: #5a5278;
}
.avatar.avatar-purple {
	background-color: rgba(90, 82, 120, 0.1);
	color: #5a5278;
}
.avatar.avatar-pink {
	background-color: rgba(90, 82, 120, 0.1);
	color: #5a5278;
}
.avatar.avatar-red {
	background-color: rgba(90, 82, 120, 0.1);
	color: #5a5278;
}
.avatar.avatar-orange {
	background-color: rgba(90, 82, 120, 0.1);
	color: #5a5278;
}
.avatar.avatar-yellow {
	background-color: rgba(232, 118, 159, 0.1);
	color: #e8769f;
}
.avatar.avatar-lime {
	background-color: rgba(232, 118, 159, 0.1);
	color: #e8769f;
}
.avatar.avatar-green {
	background-color: rgba(232, 118, 159, 0.1);
	color: #e8769f;
}
.avatar.avatar-teal {
	background-color: rgba(232, 118, 159, 0.1);
	color: #e8769f;
}
.avatar.avatar-cyan {
	background-color: rgba(232, 118, 159, 0.1);
	color: #e8769f;
}
body {
	background-color: #f1f1f1;
	color: #2c2945;
}
.page-header .right .nav-pills .nav-link {
	color: #2c2945;
}
#header_top .brand-logo {
	background: #5a5278;
}
.header {
	background: #5a5278;
}
.header .nav-tabs .nav-link {
	color: #fff;
}
.header .nav-tabs .nav-link.active {
	border-color: #fff;
}
.header .dropdown-menu .dropdown-item:hover,
.header .dropdown-menu .dropdown-item.active {
	color: #5a5278;
}
.page .section-white,
.page .section-body {
	background: #f1f1f1;
}
#header_top .nav-link {
	color: #2c2945;
}
.header_top.dark {
	background-color: #39374e !important;
}
.header_top.dark .nav-link {
	color: #f1f1f1 !important;
}
.card {
	background-color: #fff;
}
.card .card-options a {
	color: #5a5278;
}
.card .card-options a.btn {
	color: #fff;
}
.card.card-fullscreen {
	background-color: #fff !important;
}
.metismenu a {
	color: #2c2945;
}
.metismenu a:hover {
	color: #5a5278;
	border-color: #5a5278; 
}
.metismenu .active > a {
	color: #5a5278;
	border-color: #5a5278;
}
.brand-logo{
	background-color: #fff;
    border-radius: 50%;
    padding: 5px;
	color: var(--primary);
	
}


/* .metismenu .active ul .active a {
	color: #fff;
	background: transparent;
} */
.metismenu.grid > li.active > a,
.metismenu.grid > li > a:hover {
	background: rgba(90, 82, 120, 0.1);
	color: #5a5278;
}
.inbox .detail {
	background: #fff;
}
.file_folder a {
	background: #fff;
	border-color: #f1f1f1;
}
.auth .auth_left {
	background: #fff;
}
.gender_overview {
	background: #f1f1f1;
}
.table-calendar-link {
	background: #5a5278;
}
.table-calendar-link:hover {
	background: #39374e;
}
.table-calendar-link::before {
	background: #39374e;
}
.theme_div {
	background: #fff;
}
.ribbon .ribbon-box.green {
	background: #cedd7a;
}
.ribbon .ribbon-box.green::before {
	border-color: #cedd7a;
	border-right-color: transparent;
}
.ribbon .ribbon-box.orange {
	background: #5a5278;
}
.ribbon .ribbon-box.orange::before {
	border-color: #5a5278;
	border-right-color: transparent;
}
.ribbon .ribbon-box.pink {
	background: #e8769f;
}
.ribbon .ribbon-box.pink::before {
	border-color: #e8769f;
	border-right-color: transparent;
}
.ribbon .ribbon-box.indigo {
	background: #e4bd51;
}
.ribbon .ribbon-box.indigo::before {
	border-color: #e4bd51;
	border-right-color: transparent;
}
.text-orange {
	color: #e4bd51 !important;
}
.tag-info {
	background: #5a5278;
}
.tag-success,
.tag-green {
	background: #cedd7a;
}
.tag-orange {
	background: #e8769f;
}
.tag-danger {
	background: #e8769f;
}
.bg-success {
	background: #cedd7a !important;
}
.bg-danger {
	background-color: #e8769f !important;
}
.auth .auth_left {
	background: #39374e;
}
.auth .auth_left .card {
	padding: 20px;
	right: -150px;
	z-index: 99999;
}
@media only screen and (max-width: 1024px) {
	.auth .auth_left .card {
		right: -50px;
	}
}
@media only screen and (max-width: 992px) {
	.auth .auth_left .card {
		right: auto;
		z-index: 0;
	}
}
.top_dark {
	background-color: #39374e !important;
}
.sidebar_dark #left-sidebar {
	background-color: #39374e !important;
}
.sidebar_dark #header_top .nav-link {
	color: #f1f1f1 !important;
}
.sidebar_dark .sidebar .metismenu .active > a {
	color: #e4bd51;
}
.sidebar_dark .sidebar .metismenu .active ul .active a {
	color: #e4bd51;
}
.iconcolor #header_top .nav-link {
	color: #e4bd51 !important;
}
.gradient .custom-switch-input:checked ~ .custom-switch-indicator {
	background: linear-gradient(45deg, #5a5278, #e8769f) !important;
}
.gradient .metismenu.grid > li.active > a,
.gradient .metismenu.grid > li > a:hover {
	background: linear-gradient(45deg, #5a5278, #5a5278) !important;
	color: #fff;
	border: 0;
}
.gradient .btn-primary {
	background: linear-gradient(45deg, #5a5278, #e8769f) !important;
	border: 0;
}
.gradient .btn-dark {
	background: linear-gradient(45deg, #808488, #333537) !important;
}
.gradient .bg-success,
.gradient .bg-danger,
.gradient .badge-success,
.gradient .progress-bar,
.gradient .btn-danger {
	background: linear-gradient(45deg, #5a5278, #e8769f) !important;
}
.metismenu.grid > li > a {
	border-color: #5a5278;
}


.table td, .table th{border-color:#e0dee4}
.table.table-striped tbody tr:nth-of-type(2n){background: #f5f4f9;}

.page-link{color:var(--primary);min-width:30px;}
.page-item.active .page-link{background-color:var(--primary); border-color:var(--primary);}
.page-link:hover{color:var(--primary);}

.table.table-striped tbody tr.criticalPri{
    background: #ffefef;
}
.table.table-striped tbody tr.criticalPri td:first-child{
    border-left: 5px solid #FFABB3;
}

span.status{
    display:inline-block;
    font-weight:500;
    width: 80px;
    height: 27px;
    padding:3.5px;
    border: 1px solid transparent;
    border-radius: 50px;
    box-shadow: 2px 2px 6px rgb(0,0,0,0.15);
	margin-top: -5px
}
 span.status.critical{
    color: #7E1515;
    background: #FFABB3;
    border: 1px solid #FF8B96;
	text-align: center;

	
}

span.status.high{
    color: #734D14;
    background: #FFBC8C;
    border-color: #FF9D56;
	text-align: center;

	

}

span.status.medium{
    color: #906E46;
    background: #FFE8AE;
    border-color: #F5D276;
	text-align: center;

	

}
span.status.low{
    color: #347D9C;
    background: #D0F2FD;
    border-color: #AFE7F9;
	text-align: center;

	

}


@media screen and (min-width:768px){
	span.status{
		width: 60px;
		margin-bottom: 6px;
		
	}
}




.btn-primary:hover {
background:var(--secondary) !important;
    border-color:var(--secondary) !important;
}
.btn-icon{
    padding:.375rem 0.25rem !important;
    min-width: 34px;
}

.btn-outline-primary.active{
	background-color: var(--primary) !important;
	border-color: var(--primary)!important;
	color: #fff !important;
}

#left-sidebar {
    padding: 10px 0;
    height: auto;
    text-align: center;
    width: 90px;
	background: var(--primary);
	height: 100%;
	display: flex;
    flex-direction: column;
}

#left-sidebar .sidebar-nav {
    margin-right: 0;
	padding: 0;
}


.metismenu>li i {
    display: block;
    margin: auto;
    font-size: 20px;
    margin-bottom: 7px;
}
.metismenu a
{color:#d2cde3;
border-right:none;
}
.metismenu ul a{color:#e4e1ef}

.metismenu .active>a, .metismenu a:hover, .list-a.active{color:rgb(210, 205, 227);
	background-color: #433a63;
}
li.active:focus > span >a{
	background-color: inherit;
}

@media (max-width: 1024px) {
	span.status {
	  height: 20px !important;
	  margin-top: -2px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	}
  }


@media only screen and (max-width: 576px){
	span.status{
		width: 57px;
        height: 27px;
		margin-bottom: 4px;
		margin-top: 0px;
	}
}



a{
	transition: ease all 0.2s;	
}

@media screen and (min-width:768px){
	.hright .nav-link{
		z-index:1001;
	}

	
	.hright .dropdown-menu{
		background:#dfdced;
		width: 40px;
		min-width: 0;
		padding: 0;
		left: 50% !important;
		top:auto !important;
		bottom:20px;
		border-radius:50px;
		overflow:hidden;
		transform: translateX(-50%) !important;
		display: block;
		visibility: hidden;
		opacity: 0;
		transition: ease all 0.2s;
	}
	.hright .dropdown-menu .dropdown-divider{
		margin:0;
	}
	.hright .dropdown-menu .dropdown-item{
		margin: 0;
		font-size: 18px;
		text-align: center;
		color: var(--primary);
	}
	.hright .dropdown-icon{
		margin-right:0;
	}
	.hright .dropdown-menu.show{
		bottom:60px;
		display: block !important;
		visibility: visible !important;
		opacity: 1 !important;
	}
}

.form-control[type=file] {
	overflow: hidden;
	border:1px solid #ddd;
	padding: 0 10px;
	height: 32px;
	line-height: 2.4;
	border-radius:4px;
  }
  .form-control[type=file]:not(:disabled):not([readonly]) {
	cursor: pointer;
  }
  .form-control::-webkit-file-upload-button {
	padding: 0.375rem 0.75rem;
	margin: -0.375rem -0.75rem;
	-webkit-margin-end: 0.75rem;
	margin-inline-end: 0.75rem;
	color: #333;
	display:inline-block;
	height: 100%;
	pointer-events: none;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
	border-inline-end-width: 2px;
	border-radius: 0;
	-webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-control::file-selector-button {
	padding: 0.375rem 0.75rem;
	margin: -0.375rem -0.75rem;
	-webkit-margin-end: 0.75rem;
	margin-inline-end: 0.75rem;
	color: #333;
	background-color: #f4f4f4;
	pointer-events: none;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
	border-inline-end-width: 1px;
	border-radius: 0;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
	.form-control::-webkit-file-upload-button {
	  -webkit-transition: none;
	  transition: none;
	}
	.form-control::file-selector-button {
	  transition: none;
	}
  }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
	background-color: #e8e8e8;
  }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
	background-color: #e8e8e8;
  }
.datepickerwidth .react-datepicker-wrapper{
flex-basis: 0;
flex-grow: 1;
}  

.datepickerwidth  .btn-outline-secondary{
	padding: 5px 10px;
}

.btn-primary.disabled, .btn-primary:disabled{
    background-color: var(--primary);
    border-color: var(--primary);
}

.table_sticky_1col th:last-child, .table_sticky_1col td:last-child,
  .table_sticky_2col th:nth-last-child(-n+1), .table_sticky_2col td:nth-last-child(-n+1),
  .table_sticky_3col th:nth-last-child(-n+3), .table_sticky_3col td:nth-last-child(-n+3),
  .table_sticky_4col th:nth-last-child(-n+4), .table_sticky_4col td:nth-last-child(-n+4){
    position: sticky;
    right: 0;
    z-index: 1;
    background: #fff;
    padding: 0.5rem 0.65rem !important;
    width: 90px !important;
    min-width: 90px;
  }
.table_sticky_1col th,.table_sticky_2col th,.table_sticky_3col th,.table_sticky_4col th{
    background:#f8f9fa !important;
  }
  .table_sticky_1col th:last-child, .table_sticky_1col td:last-child{
    /* box-shadow:-5px 0 5px rgba(0,0,0,0.1); */
    z-index: 2;
  }
  .table_sticky_2col th:nth-last-child(2), .table_sticky_2col td:nth-last-child(2){
    right: 90px;
    /* box-shadow:-5px 0 5px rgba(0,0,0,0.1); */
  }
  .table_sticky_3col th:nth-last-child(2), .table_sticky_3col td:nth-last-child(2){
    right: 90px;
  }
  .table_sticky_3col th:nth-last-child(3), .table_sticky_3col td:nth-last-child(3){
    right: 180px;
    /* box-shadow:-5px 0 5px rgba(0,0,0,0.1); */
  }
  .table_sticky_4col th:nth-last-child(2), .table_sticky_4col td:nth-last-child(2){
    right: 90px;
  }
  .table_sticky_4col th:nth-last-child(3), .table_sticky_4col td:nth-last-child(3){
    right: 180px;
  }
  .table_sticky_4col th:nth-last-child(4), .table_sticky_4col td:nth-last-child(4){
    right: 270px;
    /* box-shadow:-5px 0 5px rgba(0,0,0,0.1); */
  }
  