.user-icon {
  background: #5a5278;
  color: #fff;
  border: 1px solid;
}

.img1 {
  width: 50px;
  vertical-align: middle;
  border-style: none;
  border-radius: 100%;
}

/* Styling the msg-header container */
.msg-header {
  width: 100%;
  min-height: 40px;
  border-bottom: none;
  display: inline-block;
  background-color: #efefef;
  margin: 0;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding-top: 0.6rem;
}
/* Styling the profile picture */
.msgimg {
  margin-left: 2%;
  float: left;
}

.container1 {
  width: 270px;
  height: auto;
  float: left;
  margin: 0;
}

/* styling user-name */
.activeP {
  margin-bottom: 0em !important;
  float: left;
  color: black;
  font-weight: bold;
  margin: 0 0 0 2px;
}
/* Styling the inbox */
.chat-page {
  padding: 0 0 50px 0;
}

.msg-inbox {
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.chats {
  padding: 0px;
}

.msg-page {
  max-height: 500px;
  overflow-y: auto;
}

/* Styling the msg-bottom container */
.msg-bottom {
  border-top: 1px solid #ccc;
  position: relative;
  height: 11%;
  background-color: rgb(239 239 239);
}
/* Styling the input field */

/* Styling the avatar  */
received-chats-img {
  width: 50px;
  float: left;
}

.received-msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
  margin-bottom: 1rem;
}
.received-msg-inbox {
  width: 50%;
}

.received-msg-inbox .block {
  background: #efefef none repeat scroll 0 0;
  border-radius: 10px;
  color: #646464;
  font-size: 14px;
  margin-left: 1rem;
  padding: 1rem;
  width: 100%;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 5px 2px;
}
.block {
  overflow-wrap: break-word;
}

/* Styling the msg-sent time  */
.time {
  color: #777;
  display: block;
  font-size: 12px;
  margin: 4px 0 0;
}
.outgoing-chats {
  overflow: hidden;
  margin: 15px 0px;
  margin-bottom: 0;
}

.outgoing-chats-msg .block {
  background: #5a5278;
  text-align: right;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
  padding-top: 1.2rem;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 5px 2px;
}
.outgoing-chats-msg {
  margin-bottom: 1rem;
  float: right;
  width: 50%;
}

/* Styling the avatar */
.outgoing-chats-img {
  width: 50px;
  float: right;
  margin-right: 1rem;
}

.ql-toolbar.ql-snow {
  background: #f8f8f8;
}
.item {
  white-space: nowrap;
  position: relative;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 130px;
  box-shadow: inset 3px 3px 10px rgba(0, 0, 0, 0.05);
  background: #fefefe;
}

.itemData {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.leftContainer {
  width: 75%;
}

.priorityButton {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
}

.avatarSize{
    font-size: 12px;
}

.descriptionBottomMargin {
  margin-bottom: 20px;
}


@media only screen and (max-device-width: 850px) {
  *,
  .time {
    font-size: 13px;
  }
  img {
    width: 65px;
  }
  .msg-header {
    height: 5%;
  }
  .msg-page {
    max-height: none;
  }
  .received-msg-inbox p {
    font-size: 13px;
  }
  .outgoing-chats-msg p {
    font-size: 13px;
  }
  .avatarSize{
    font-size: 13px;
}

  .received-msg-inbox {
    width: 60%;
  }

  .outgoing-chats-msg {
    width: 60%;
  }
}

@media (max-width: 576px) {
  .received-msg-inbox {
    width: 100%;
  }

  .received-msg-inbox .block {
    margin-left: 0;
  }

  .outgoing-chats-msg {
    width: 90%;
  }

  .leftContainer {
    width: 100%;
  }
  .time {
    font-size: 13px;
  }
  .avatarSize{
    font-size: 13px;
}
}

@media only screen and (max-device-width: 450px) {
  *,
  .time {
    font-size: 13px;
  }

  img {
    width: 65px;
  }
  .msg-header {
    font-size: smaller;
    height: 5%;
    padding:5px
  }
  .msg-page {
    max-height: none;
  }
  .received-msg-inbox p {
    font-size: 13px;
  }
  .outgoing-chats-msg p {
    font-size: 13px;
  }
}
