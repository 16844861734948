.bgColor{
    background-color: #433a63;
}

.inactive{
    background-color: #5a5278;
}

/* sidebar.module.css */
.sidebar {
    transition: transform 0.3s ease;
  }
  
  .sidebar.open {
    transform: translateX(0);
  }
  
  .sidebar:not(.open) {
    transform: translateX(-100%);
  }
  
  
  @media (min-width: 768px) {
    .sidebar {
      transform: translateX(0);
    }
  }

  @media (max-width: 767px) {
    .sidebarOpen{
      display: block;
    }

    .sidebarClose{
      display: none;
    }
  }
  