.switch {
    --false: #E81B1B;
    --true: #009068;
   }
   
   input[type=checkbox] {
    appearance: none;
    height: 1.1rem;
    width: 2.2rem;
    background-color: #e8e8e8;
    position: relative;
    border-radius: 1em;
    cursor: pointer;
    margin-top: 10px !important;
    box-shadow: inset 3px 3px 5px rgba(0,0,0,0.3);
   }
   
   input[type=checkbox]::before {
    content: '';
    display: block;
    height: 1em;
    width: 1em;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 3px;
    background-color: var(--false);
    border-radius: 1em;
    transition: .3s ease;
   }
   
   input[type=checkbox]:checked::before {
    background-color: var(--true);
    left: calc(50% + 2px);
   }

   .toggleContainer2{
    display: flex;
              justify-content: start;
              /* align-items: center; */
              gap: 10px
  }

  .headerContainer{
    display: flex !important;
    justify-content: space-between;
  }

  


  .p-sidebar .p-sidebar-content{
    padding: 0;
  }

  /* .p-sidebar .p-sidebar-header{
    position: absolute;
  } */

  .p-sidebar .p-sidebar-header{
    display: none;
    position: fixed;
    z-index: 2;
    right: 2px;
    top: 2px;
    background-color: #ff5c5c;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    justify-content: center;
  }

  .p-sidebar-header button{
    color: #fff;
  }

  .p-calendar .p-datepicker-trigger {
    background-color: #5a5278 !important; 
    border: 1px solid #5a5278 !important;
    width: 30px !important;
  }
  @media(max-width: 576px){
    .headerContainer{
      flex-direction: column-reverse;
      position: relative;
    }
    .chatContainer{
        display: block !important;
        justify-content: space-between;
        gap: 10px;
      }

      .positionContainer{
        position: absolute !important;
        top: 5px;
        right: 62px;
      }
  }
  @media(max-width: 768px){
    
    .chatContainer{
        display: block !important;
        gap: 0px;
      }
      
  }

 