.invalidDataError {
    color: red !important;
    font-size: small;
}

.checkboxHeight{
    height: min-content !important;
}

.rowWrap{
    white-space: nowrap;
}