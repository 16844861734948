.invalidDataError {
    color: red !important;
    font-size: x-small;
}

/* Add this to your CSS stylesheet or style section */
.attachment-link {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--primary);
  font-size: x-large;
  transition: color 0.3s ease;
}

.attachment-link:hover .view-attachment-text {
  opacity: 1; /* Show on hover */
}

.view-attachment-text {
  position: absolute;
  top: 50%;
  right: 100%; /* Adjust as needed */
  transform: translate(-100%, -50%);
  white-space: nowrap;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease;
}

