.contentSize {
  font-size: 16px;
  
}

.addButton {
  padding: 6px;
  position: relative;
  right: 10px;
  top: 0px;
  background: #5a5278;
  display: flex;
  color: #fff !important;
  align-items: center;
  border-radius: 4px;
  min-height: 0;
  font-size: 16px !important;
  height: min-content;
}

.my_sort_cut i{
    font-size: 15px;
}

.flexContainer{
    display: flex;
    justify-content: space-between;
    padding: 15px;
    justify-content: flex-end;
}

.cardSize{
  max-width: 150px;
}


.cardAlign{
  justify-content: end;
}

@media(max-width:576px){
    .flexContainer{
      display: block;
    }

    .btnSize{
      font-size: 11px;
    }

    .cardSize{
      max-width: 100%;
    }
    
    
    .cardAlign{
      justify-content: space-between;
    }
}


