body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* #header_top .nav-link {
  padding: 5px !important;
} */

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 0.8rem !important;
  height: 2rem !important;
}
.css-1uvydh2 {
  padding: 5.5px 0px 5.5px 14px !important;
}

input::placeholder {
  color: #495057 !important;
}

.text-muted1{
  color: #5a5278 !important;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear,
input[type="password"]::-webkit-clear-button,
input[type="password"]::-webkit-inner-spin-button,
input[type="password"]::-webkit-outer-spin-button {
  display: none;
}


.p-badge {
  background: #2196f3;
  color: #ffffff;
  font-size: 10px;
  font-weight: 700;
  min-width: 15px;
  height: 15px;
  line-height: 15px;
}
.notificationModel{
  position: absolute;
   z-index: 999;
   right:-35px
}
.notificationModel .card{
  width: 350px;
  max-width: 90%;
  height: min-content;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 0px;
  top: -23px;
  border: 1px solid #ddd;
}

.p-card .p-card-body{
  padding: 0px;
}

.p-card .p-card-content{
  padding: 0px;
}

.pi{
  font-size: 17px;
}

@media(max-width: 576px){
  
  .notificationModel .card{
    height: 300px;
    width: 300px;
    right: 10px;
  }
}