.fixed-controls {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.priorityContainer {
  font-size: 10px;
  display: flex;
  gap: 10px;
  width: 100%;
}

.priorityCritical {
  border: 1px solid #ff5c5c;
  border-radius: 10px;
  /* width: 100%; */
  width: 25%;
  height: min-content;
  background-color: #ffabb32b;
}

.priorityHigh {
  border: 1px solid #ff952b;
  border-radius: 10px;
  /* width: 100%; */
  width: 25%;
  height: min-content;
  background-color: #ffbc8c36;
}

.priorityMedium {
  border: 1px solid #f6c32d;
  border-radius: 10px;
  /* width: 100%; */
  width: 25%;
  height: min-content;
  background-color: #ffe8ae42;
}

.priorityLow {
  border: 1px solid #308cd0;
  border-radius: 10px;
  /* width: 100%; */
  width: 25%;
  height: min-content;
  background-color: #d0f2fd59;
}



.flexHeader{
  display:flex; 
  justify-content: space-between;
  align-items: center;
}

.count{
  background-color: white;
  padding: 4px;
  border-radius: 50%;
  min-height: 28px;
  min-width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.critical {
  color: white;
  background: #ff5c5c;
  border: 1px solid #ff8b96;
  display: inline-block;
  width: 100%;
  font-weight: 600;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 2px 2px 6px rgb(0, 0, 0, 0.15);
}


.high {
  color: white;
    background: #ff952b;
    border-color: #ff9d56;
    display: inline-block;
    width: 100%;
    font-weight: 600;
    padding: 5px 10px;
    font-size: 15px;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 2px 2px 6px rgb(0, 0, 0, 0.15);
}

.medium {
  color: white;
  background: #f6c32d;
  border-color: #f5d276;
  display: inline-block;
  width: 100%;
  font-weight: 600;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 2px 2px 6px rgb(0, 0, 0, 0.15);
}
.low {
  color: white;
  background: #308cd0;
  border-color: #afe7f9;
  display: inline-block;
  width: 100%;
  font-weight: 600;
  padding: 5px 10px;
  /* color: white; */
  font-size: 15px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 2px 2px 6px rgb(0, 0, 0, 0.15);
}


.card {
  margin: 10px;
  border: 0px solid gray;
  border-radius: 5px;
  /* height:25vh; */
  background-color: #ffffff;
  box-shadow: 2px 2px 6px rgb(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: -1;
}

.parentCard {
  max-height: 60vh;
  overflow-y: auto;
  min-height: 10vh;
}

.button {
  border: 1px solid #28a745;
  padding: 0px 5px;
  color: #28a745;
  border-radius: 3px;
}

.button:hover {
  background-color: #28a745;
  color: white;
}

.reportContainer{
    gap: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between
}


.date {
  margin: 10px 20%;
  border: 0px solid gray;
  border-radius: 5px;
  /* height:25vh; */
  background-color: #ffffff;
  box-shadow: 2px 2px 6px rgb(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}
@media (max-width: 1024px) {
  .priorityContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .priorityCritical,
  .priorityHigh,
  .priorityMedium,
  .priorityLow {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .priorityContainer {
    display: flex;
    flex-direction: column;
  }
}

@media(max-width:576px){
  .reportContainer{
    flex-direction: column;
}
}
