.card {
  padding: 15px;
  margin-bottom: 1rem;
  border-radius: 10px;
  box-shadow: 15px 15px 30px #bebebe,
             -15px -15px 30px #ffffff;
}

.invalidDataError {
  color: red !important;
  font-size: small;
}


